.weeklyQuizKachel {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
}

h1 {
  text-align: center;
  color: #333;
}

.daysRemaining {
  text-align: center;
  margin-bottom: 20px;
}

.kachelContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.kachel {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.kachelImage {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
}

.kachelContent {
  flex: 1;
}

 /* Progress Bar Anfang */

 .progressBar {
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  height: 20px;
  width: 50%;              /* Breite der Progress Bar */
  margin: 20px auto;          /* Zentriert horizontal */
  position: relative;      /* Relativ positioniert, um das Fortschrittselement absolut positionieren zu können */
  top: 50%;                /* Vertikal zentriert */
  transform: translateY(-50%); /* Korrigiert die Verschiebung um die Hälfte der Höhe */
}

.progress {
  height: 100%;
  border-radius: 15px;
  transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;
  position: absolute;
  right: 0;               /* Startet von der rechten Seite */
  background-color: red;  /* Standardfarbe rot */
}

 /* Progress Bar Ende */

.startButton {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.startButton:hover {
  background-color: #45a049;
}


.startButton {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ff7e5f;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.startButton:hover {
  background-color: #ff6b4a;
}


.googleLogin {
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #4285F4; /* Adds a border to make it look like a card */
  border-radius: 25px; /* Makes the button more rounded */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.googleLogin img.google-icon {
  margin-left: 8px;
  width: 30px; /* Reduced size to fit better */
  height: 30px;
}

.googleLogin:hover {
  background-color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a larger shadow on hover */
}

.googleLogin:active {
  background-color: #e2e2e2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduces shadow on click */
}

.button-19 {
  appearance: button;
  background-color: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-top: 30px;
}
.button-19-disabled{
  appearance: button;
  background-color: #464646;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;

  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  margin-top: 30px;
  cursor: not-allowed;
}
.button-19-disabled:after {
  background-clip: padding-box;
  background-color: #464646;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  cursor: not-allowed;
}
.button-19:after {
  background-clip: padding-box;
  background-color: #1CB0F6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

.button-19:active {
  border-width: 4px 0 0;
  background: none;
}


.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}


.large-speech-bubble {
  position: absolute;
  bottom: -100px; /* Abstand vom Button anpassen */
  left: -110%;
  top: 130%;
  transform: translateX(-50%);
  background:#24a424;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  padding: 1em;
  height: 300px;
  width: 300px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.large-speech-bubble-content {
  padding: 1em;
}

.large-speech-bubble-arrow {
  position: absolute;
  top: -10px; /* Position des Dreiecks anpassen */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #24a424; 
}

.large-speech-bubble-disabled {
  position: absolute;
  bottom: -100px; /* Abstand vom Button anpassen */
  left: -110%;
  top: 130%;
  transform: translateX(-50%);
  background:#b1b1b1;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  padding: 1em;
  height: 300px;
  width: 300px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
.large-speech-bubble-arrow-disabled {
  position: absolute;
  top: -10px; /* Position des Dreiecks anpassen */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #b1b1b1; 
}
.speech-bubble {
  position: absolute;
  bottom: 70px; /* Position der Sprechblase über dem Button */
  transform: translateX(-50%);
  background-color: #d0f0c0; /* Helles Grün als Hintergrundfarbe */
  border: 1px solid #a0d6a0; /* Etwas dunkleres Grün für den Rand */
  padding: 12px 18px; 
  border-radius: 12px; /* Abgerundete Ecken */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Sanfter Schatten für Tiefe */
  font-size: 16px; /* Größere Schriftgröße für bessere Lesbarkeit */
  color: #004d00; /* Dunkles, modernes Grün für den Text */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
  z-index: 10;
  left: 20px;
  max-width: 180px; /* Maximale Breite der Sprechblase */
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modernere Schriftart */
}

.speech-bubble::before {
  content: "";
  position: absolute;
  top: 100%; /* Pfeil wird unterhalb der Sprechblase positioniert */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #d0f0c0 transparent transparent transparent; /* Pfeil in der Farbe der Sprechblase */
}
.svg-background {
  position: absolute;
  top: 50px; /* Ändere dies, um es entsprechend der Höhe deiner Buttons anzupassen */
  left: 0;
  width: 100%;
  height: auto; /* Stelle sicher, dass das SVG die korrekte Höhe beibehält */
}


.curved-container {
  position: relative;
  width: 100%;
  height: 1500px;
  top: 100px;
  margin:  auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: url('https://firebasestorage.googleapis.com/v0/b/internet-fuererschein.appspot.com/o/llline%20(5).svg?alt=media&token=351b0fdc-0f3b-490a-b2ae-32b5425c8980') no-repeat center bottom;
  background-size: cover;

}



.curved-item {
  display: inline-block;
  margin: 10px;
}

.curved-item {
  position: relative;
  margin: 40px 0;
}

.curved-item.left {
  transform: translateX(-80%);
}

.curved-item.right {
  transform: translateX(80%);
}



.dashed-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 5px,
    black 5px,
    black 10px
  );
  transform: translateX(-50%);
}




.profile-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 30;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-name {
  font-size: 18px;
}

.logout-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #ff3333;
}


.button-10 {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-10:hover {
  background-color: #0056b3;
}

button.button-10 {
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
}
button.button-10 {
  touch-action: manipulation;
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #24a424;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.button-10::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #24a424;
  border-radius: inherit;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.button-10:hover {
  background: #0a5c0a;
  transform: translate(0, 0.25em);
}
button.button-10:hover::before {
  box-shadow: 0 0 0 2px #ffffff, 0 0.5em 0 0 #ffe3e2;
  transform: translate3d(0, 0.5em, -1em);
}
button.button-10:active {
  background: #0f430f;
  transform: translate(0em, 0.75em);
}
button.button-10:active::before {
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  transform: translate3d(0, 0, -1em);
}


.button-10-disabled {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-10-disabled:hover {
  background-color: #0056b3;
}

button.button-10-disabled {
  touch-action: manipulation;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
}
button.button-10-disabled {
  touch-action: manipulation;
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #fff0f0;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.button-10-disabled::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9c4d2;
  border-radius: inherit;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.button-10-disabled:hover {
  background: #ffe9e9;
  transform: translate(0, 0.25em);
}
button.button-10-disabled:hover::before {
  box-shadow: 0 0 0 2px #ffffff, 0 0.5em 0 0 #ffe3e2;
  transform: translate3d(0, 0.5em, -1em);
}
button.button-10-disabled:active {
  background: #ffe9e9;
  transform: translate(0em, 0.75em);
}
button.button-10-disabled:active::before {
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  transform: translate3d(0, 0, -1em);
}



.mordern-Link-Div {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Zentriert den Inhalt vertikal */
  align-items: center;     /* Zentriert den Inhalt horizontal */
  width: 100%;             /* Stellt sicher, dass der Container die gesamte Breite einnimmt */
  max-width: 400px;        /* Optional: Maximale Breite für größere Bildschirme */
  margin: 0 auto; 
  margin-top: 10%;
  text-align: center;
  padding: 20px;          /* Optional: Etwas Padding für besseren Abstand */
  background-color: white; /* Stellt sicher, dass die Kachel weiß ist */
  border-radius: 10px;    /* Optional: Rundet die Ecken der Kachel ab */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Fügt einen leichten Schatten hinzu */
}

.modern-Link {
  text-decoration: underline;
  background-color: #007BFF;
  color: #FFFFFF;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.modern-Link:hover {
  background-color: #0056b3; /* Optional: Ändert die Hintergrundfarbe beim Hover */
}

.Reorder.Group{
  background-color: #0056b3;
  
}
.range-slider {

  width: 100%;
  margin: auto;
  top:20%;

  max-width: 500px;
}

input[type="range"] {
  margin-top: 10%;
  width: 80%; /* Hier habe ich die Breite auf 80% geändert */
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  background: #ccc;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.value-label {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #007bff;
}

.radio-group{
 margin-right: 10%;
}

.radio input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  margin-left: 10%;
  
  border: 2px solid #007bff;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.radio input[type="radio"]:checked {
  background-color: #007bff;
}

.Erklärungscard-Div {
  width:70%;
  height: 80%;
  max-height: 700px;
  max-width: 1200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;

}

.Erklärungscard-Div-Choice1 {
  width: 100%;
  height: 80%;
  max-height: 700px;
  max-width: 1200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  text-align: center; /* Zentriert den Text */
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Erklärungscard-Div-Choice2 {
  width: 110%;
  height: 80%;
  max-height: 700px;
  max-width: 1200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  text-align: center; /* Zentriert den Text */
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Erklärungscard-Div-Choice3 {
  width: 90%;
  height: 80%;
  max-height: 700px;
  max-width: 1200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  text-align: center; /* Zentriert den Text */
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Rechtsbündige Ausrichtung der Optionen */
  width: 100%; /* Optionen über die gesamte Breite strecken */
}

.options div {
  margin-bottom: 8px;
}


.Erklärungscard-Div4 {
  width:70%;
  height: 80%;
  max-height: 800px;
  max-width: 1200px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;

}


.Seitenanzahl{
position:absolute;
font-weight: bold;
top:5px;
right: 20px;
}
.body-div {
  margin-top: 20px;
  text-align: justify;
  overflow: hidden;
  max-width: 100%;
  white-space: pre-line;
}
.body-div a {
  margin: 0;
  padding: 0;
  display: inline; /* Setze den Wert auf 'inline' oder entferne diese Zeile ganz */
  white-space: pre-line; /* Füge diese Zeile hinzu */
}
.body-div p {
  margin: 0;
  padding: 0;
  display: inline; /* Ändere 'display: block;' zu 'display: inline;' */
 
}
.Lektion{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Die volle Höhe des Viewports */
  background-color: #ffffff;
}
.kostenloseVariante{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Die volle Höhe des Viewports */
  background-color: #330033;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='1000' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.Erklärungscard-Div h2 {
  font-size: 1.5rem;
  position: relative;
  margin-top: 0px;
}
.ConnectionDiv {
  height: 55px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ConnectionDiv:hover {
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}



.Pfeil-Right {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;

}
.Pfeil-Right-Diabled{
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor:not-allowed;
}
.Pfeil-Right-Diabled img {
  filter: grayscale(100%); /* Graustufenfilter zu 100% anwenden */
}
.Pfeil-Left {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;

}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-label {
  font-size: 14px;
  margin-bottom: 6px;
  color: #333;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #555;
  transition: border-color 0.3s ease;

}

.input-field:focus {
  border-color: #007bff;
  outline: none;
}


.login-headline{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* Anpassung für Smartphones */
@media (max-width: 768px) {
  .login-headline {
    font-size: 1.5rem; /* Kleinere Schriftgröße auf Smartphones */
  }
}

.Login_Form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 60%;
  background-color: whitesmoke;
  align-items: center;
  display: grid;
  justify-content: center;
  align-items: center;

  /*Class-Effect*/
  background: rgba(255, 255, 255, 0.52);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(255, 255, 255, 0.24);
  z-index: 300;
}
.Login_Form label{
  color: black;
  font-weight: bold;
}
.Login_Form a {
  color: #333; /* Dunkelgraue Farbe */
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none; /* Keine Unterstreichung */
  transition: color 0.3s ease;
  text-decoration: underline;
}

.Login_Form a:hover {
  color: #007bff; /* Blaue Farbe beim Hovern */
  text-decoration: none;
}
.Login_Form h1{
color: white;
}
.Login_Form img{
  margin: auto 0;
}
.curved{
 
  color: white;
  text-align: center;

  background-color: #330033;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23680068' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%238F008F'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    border: 0;
  height: 1px;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
}


.curved h1{
  font-size: 6rem;
  font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top:0;
}

.curved p{
font-size: 1rem;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
margin: 0 5rem 0 5rem;
}
.curved svg {
  width: 100%; /* Oder die gewünschte Breite */
  height: auto; 
}
.first-Div{
  display: flex;
  background-color: rgb(235, 243, 253);
  border-radius: 10px 10px 30px 0;
  height: 60vh;
  min-height: 400px;
  max-height: 500px;
	border-bottom-left-radius: 60%;
	border-bottom-right-radius: 60%;
}
.untergrund-Div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(45deg, #FF512F 0%, #f09619e9  51%, #ff522f24  100%);
  border-radius: 10px 10px 30px 0;
  height: 60vh;
  min-height: 400px;
  max-height: 500px;
  border-bottom-left-radius:45%;
  border-bottom-right-radius: 60%;
}
.Kategorie{
  border-radius: 30px 10px 50px 90px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 40%;
  border-top-left-radius: 90%;
  border-top-right-radius: 20%;
  min-height: 400px;
  background-image: linear-gradient(45deg, #FF512F 0%, #f09619e9 51%, #ff522f24 100%);
  display: flex;
  align-items: center;
  width: 100%;
}
.Kategorie p{
  font-size: 100%;
}

.team-image {
  display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: auto;
}

.startseiteloggedin{
align-items: center;
}

.Überschrift{
  font-size: 50px;
  font-weight: 700;
  background-image: linear-gradient(to right, #553c9a 45%, #ee4b2b);
  color: transparent ;
  background-clip: text;
  -webkit-background-clip: text;
}
.Überschrift_Animiert{
  font-size: 60px;
  font-weight: bolder;
  background-image: linear-gradient(to right, #553c9a 45%, #ee4b2b);
  color:#553c9a;
  background-clip: text;
  -webkit-background-clip: text;
}
.Überschrift-Div {
  padding-top: 7%;
  text-align: left; /* Links-Ausrichtung */
  padding-left: 5%; /* Abstand zur linken Seite */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.circle-container {
  width: 200px; /* Durchmesser des Kreises */
  height: 200px;
  border-radius: 100%; /* Macht das Element zu einem Kreis */
  background-color: transparent;
  overflow: hidden; /* Sorgt dafür, dass das Bild im Kreis bleibt */
  margin-right: 20px; /* Abstand zwischen Circle und Überschrift */ 
  margin-top: 7%;
  margin-left: 2%;
  
}

.circle {
  width: 200px; /* Durchmesser des Kreises */
  height: 200px;
  border-radius: 50%; /* Macht das Element zu einem Kreis */
  background-color: rgba(0, 0, 0, 0.2); /* Durchsichtiger Hintergrund */
  overflow: hidden; /* Sorgt dafür, dass das Bild im Kreis bleibt */
  border: 5px solid #ffffff; 
}

.circle-image {
  width: 100%;
  height: 150%;
  object-fit: cover; /* Skaliert das Bild, um den gesamten Kreis auszufüllen */
}
.einloggen{
 position: relative;
 left:75%;
 cursor: pointer;
  margin-top: 3px;
  color: rgb(76, 76, 249);
  font-weight: bold;
}
.menue{
  width: 40px;
  height: 40px;
  position: relative;
  left: 78%;
  margin-top: -5px;
  margin-right: 5px;
  cursor: pointer;
}


.logOut{
  width: 50px; 
  height: 50px; 
  position: absolute;
  top: 0px;
  right: 45px;
  cursor: pointer;
}

.profil{
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.profil:hover{
  transform: scale(1.2);
}

.Angebote-Div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Angebot-Div {
  width: 79%;
  margin-top: 20px;
  margin-bottom: 40px; /* Hier wird der Abstand zum nächsten div vergrößert */
  padding: 20px;
  background-color: rgb(186, 184, 246);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 2px 28px 47px 4px rgba(0,0,0,0.62); 
  box-shadow: 0px 10px 13px -7px #000000, 2px 28px 47px 4px rgba(0,0,0,0.62);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.Angebot-Div:hover {
  box-shadow: 0px 10px 13px -7px #000000, 2px 28px 47px 4px rgba(0, 0, 0, 0.942);
}

.Angebot-Div a {
  margin-bottom: 10px;
}
.Angebot-Div h3 {
 margin-top: 0%;
 text-align: center;
 text-decoration:underline; 
}
.Link{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Link-Anmeldung {
  position: fixed; /* Positioniert das Element relativ zum Viewport */
  top: 10px; /* Abstand vom oberen Rand des Viewports */
  left: 10px; /* Abstand vom linken Rand des Viewports */
  z-index: 9999; /* Höherer Wert als andere Elemente, um sicherzustellen, dass es im Vordergrund ist */
}

.abzeichenLeaderboard {
  margin-top: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  background-color: rgb(186, 184, 246);;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.containerGefahren{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}

.containerGefahren .Link {
  flex: 1;
  margin: 20px;
  text-align: center;
}

.containerGefahren .Link .button-87{
  margin: 0;
  width: 300px;
  height: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.containerQuizze {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.containerQuizze .Link{
  flex: 1;
  margin: 20px;
  text-align: center;
}

.containerQuizze .Link .button-87{
  margin: 0;
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Kategorien{
  text-align: center;
  color: #111; font-family: 'Helvetica Neue', sans-serif; font-size: 50px; font-weight: bold; letter-spacing: -1px; line-height: 1; text-align: center;
}
.Impressum-Div {
  display: grid;
  grid-template-columns: auto 1fr auto; /* Links, Inhalt, Rechts */
  grid-template-rows: auto 1fr auto;    /* Oben, Inhalt, Unten */
  margin-top: 10%;
  height: 150px;
  background-color: rgb(0, 0, 0);
  color: white;
  align-items: center; /* Zentrierung der Inhalte vertikal */
  padding: 10px; /* Innenabstand für den Inhalt */
}

.impressum-text h1 { /* Nur das Wort Impressum */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.impressum-text p { /* Impressum Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-Impressum {
  text-align: center;
  position: relative;
 margin-top: -70px;
  max-width: 100px; /* Maximalbreite des Logos */
}

.ueberuns-text h1 { /* Nur das Wort Über uns */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.ueberuns-text p { /* Über uns Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feedback-text h1 { /* Nur das Wort Feedback */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.feedback-text p { /* Feedback Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agb-text h1 { /* Nur das Wort AGB */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.agb-text p { /* AGB Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Gefahrenseite-text h1 { /* Nur das Wort Gefahrenseite */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.Gefahrenseite-text p { /* Gefahrenseite Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(186, 184, 246);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gefahr-text h1 { /* Nur das Wort Gefahrenseite */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.gefahr-text p { /* Gefahrenseite Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color:rgb(186, 184, 246);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-container h2 { 
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.video-container iframe { 
  display: block; /* sorgt dafür, dass das Element als Block behandelt wird */
  margin-top: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(186, 184, 246);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.header-buttons {
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 1000; /* Damit der Button immer oben bleibt */
}

.daily-quiz-button-container {
  position: fixed;
  left: 20px; /* Position vom linken Rand */
  top: 250px; /* Position vom oberen Rand */
  z-index: 1000; /* Über allen anderen Elementen */
}

.daily-quiz-button {
  background-color: #007bff; /* Hintergrundfarbe des Buttons */
  color: white; /* Textfarbe */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.daily-quiz-button:hover {
  background-color: #0056b3; /* Hintergrundfarbe beim Hover */
}


.gefahrengesamtuebung-text h1 { /* Nur das Wort Gefahrengesamtübung */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.gefahrengesamtuebung-text p { /* Gefahrengesamtseite Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quiz-text h1 { /* Nur das Wort Gefahrengesamtübung */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.quiz-text p { /* Gefahrengesamtseite Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quizgesamtuebung-text h1 { /* Nur das Wort Gefahrengesamtübung */
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.quizgesamtuebung-text p { /* Gefahrengesamtseite Text */
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

nav {
  display: flex; /* Um die Links nebeneinander anzuordnen */
  gap: 10%; /* Abstand zwischen den Spalten */
}
.Angebot-Div nav{
  display: flex;
  margin-left: -0%;
  gap: 20%; 
}
.column {
  padding-left: 25%;
  display: flex;
  flex-direction: column;
  gap: 30px; /* Abstand zwischen den Links in einer Spalte */
}
.column1 {

  display: flex;
  flex-direction: column;
  gap: 20px; /* Abstand zwischen den Links in einer Spalte */
  margin: 0 auto;
}
.column1 a{
  color: black;
}

nav a {
  text-decoration: none;
  color: white;
}
.button-87-disabled {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  width: 70%;
  align-self: center;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #000000;
  background-image: linear-gradient(45deg,gray 0%, gray  51%, gray  100%);
  cursor:not-allowed;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87 {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  width: 70%;
  align-self: center;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}
.punkt {
  display: flex;
  gap: 5px;
}
.triangle-container {
  position: relative;
  width: 200px; /* Breite des Dreiecks anpassen */
  height: 173.2px; /* Höhe des Dreiecks anpassen */
  overflow: hidden;
}

.ButtonGefahren {
  background-color: #ff4c4c; /* Rote Hintergrundfarbe */
  color: white; /* Weiße Schriftfarbe */
  border: none; /* Kein Rand */
  padding: 15px 32px; /* Innenabstand */
  text-align: center; /* Zentrierte Ausrichtung */
  text-decoration: none; /* Keine Unterstreichung */
  display: inline-block; /* Inline-Block */
  font-size: 16px; /* Schriftgröße */
  cursor: pointer; /* Zeiger-Cursor beim Überfahren */
  border-radius: 12px; /* Abgerundete Ecken */
  transition: background-color 0.3s ease; /* Sanfter Übergang für Hintergrundfarbe */
  justify-content: center; /* Vertikal zentriert */
  align-items: center; /* Horizontal zentriert */
}

.ButtonGefahren:hover {
  background-color: #e04343; /* Etwas dunkleres Rot beim Überfahren */
}

.Gefahr {
  background-color: #ff4c4c; /* Rote Hintergrundfarbe */
  color: white; /* Weiße Schriftfarbe */
  border: none; /* Kein Rand */
  padding: 15px 32px; /* Innenabstand */
  text-align: center; /* Zentrierte Ausrichtung */
  text-decoration: none; /* Keine Unterstreichung */
  display: inline-block; /* Inline-Block */
  font-size: 46px; /* Schriftgröße */
  cursor: pointer; /* Zeiger-Cursor beim Überfahren */
  border-radius: 12px; /* Abgerundete Ecken */
  transition: background-color 0.3s ease; /* Sanfter Übergang für Hintergrundfarbe */
  justify-content: center; /* Vertikal zentriert */
  align-items: center; /* Horizontal zentriert */
}

.Gefahr:hover {
  background-color: #e04343; /* Etwas dunkleres Rot beim Überfahren */
}

.Quiz {
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  color: white; /* Weiße Schriftfarbe */
  border: none; /* Kein Rand */
  padding: 15px 32px; /* Innenabstand */
  text-align: center; /* Zentrierte Ausrichtung */
  text-decoration: none; /* Keine Unterstreichung */
  display: inline-block; /* Inline-Block */
  font-size: 46px; /* Schriftgröße */
  cursor: pointer; /* Zeiger-Cursor beim Überfahren */
  border-radius: 12px; /* Abgerundete Ecken */
  transition: background-color 0.3s ease; /* Sanfter Übergang für Hintergrundfarbe */
  justify-content: center; /* Vertikal zentriert */
  align-items: center; /* Horizontal zentriert */
}

.Quiz:hover {
  background-color: #1d812b; /* Etwas dunkleres Rot beim Überfahren */
}

/* Anfang Lückentext Quiz CSS */

.quiz-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.sentence {
  font-size: 1.2em;
  margin: 20px 0;
}

.word-bank {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.word {
  background-color: #ddd;
  padding: 10px;
  border: 1px solid #999;
  cursor: pointer;
}

.dropzone {
  display: inline-block;
  width: 100px;
  border-bottom: 2px dashed #000;
  margin: 0 5px;
  min-height: 25px;
  vertical-align: middle;
}

.app {
  text-align: center;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
}

/* Ende Lückentext Quiz CSS */

/* Anfang Button zurück zur Hauptseite CSS */

.logoHS {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 100px; /* Passe die Größe des Logos nach Bedarf an */
  height: auto; /* Erhält das Seitenverhältnis */
}

/* Ende Button zurück zur Hautseite CSS */

/* Anfang Button zurück */

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px; /* Passe die Größe des Logos nach Bedarf an */
  height: auto; /* Erhält das Seitenverhältnis */
}

.logo:hover{
  transform: scale(1.2);
}

/* Ende Button zurück */

/* Anfang CSS für Verbinde Quiz */
.quiz-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.quiz-grid {
  display: flex;
  justify-content: space-around;
}

.questions, .answers {
  width: 45%;
}

.question-item, .answer-item {
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
}

.question-item input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.connected {
  background-color: lightgreen;
}

.react-lineto {
  position: absolute;
  z-index: 1; /* Ensure lines are above items */
}
/* Ende CSS für Verbinde Quiz*/


/* Profil Icon Overlay Anfang */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darker background for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the modal is on top of other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  z-index: 1001; /* Ensure the modal content is on top of the overlay */
}

.modal-content h2 {
  color: white; /* Set the color of the heading text to white */
  margin: 0;
  font-family: Arial, sans-serif; /* Ensure the font is consistent */
}

.modal-content p {
  color: white; /* Set the color of the paragraph text to white */
  font-family: Arial, sans-serif; /* Ensure the font is consistent */
  margin: 10px 0;
}

.close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white; /* Set text color to white */
  border: 2px solid #0056b3; /* Add a border with a darker color */
  border-radius: 5px;
  cursor: pointer;
  font-family: Arial, sans-serif; /* Ensure the font is consistent */
  font-size: 16px; /* Adjust font size if needed */
}

.close-button:hover {
  background-color: #0056b3;
  border-color: #003d7a; /* Darker border color on hover */
}

/* Profil Icon Overlay Ende */

/* Leaderboard beginn */

.leaderboard-button-container {
  position: fixed;
  left: 20px; /* Position vom linken Rand */
  top: 100px; /* Position vom oberen Rand */
}

.leaderboard-button {
  background-color: transparent; /* Hintergrund entfernt */
  border: none; /* Rahmen entfernt */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Hintergrund entfernt */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: transparent; /* Hintergrund entfernt */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

/* Leaderboard Ende */


/* Info-Button beginn */

/* Add these styles to your App.css or another stylesheet */

.info-button-container {
  position: fixed;
  right: 20px; /* Position vom linken Rand */
  top:100px; /* Position vom oberen Rand */
}

.info-button {
  background-color: transparent; /* Hintergrund entfernt */
  border: none; /* Rahmen entfernt */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

/* Info-Button Ende */

/* Aufgaben-Kachel beginn */

/* Add these styles to your App.css or another stylesheet */

.weekly-quiz-kachel-container {
  position: fixed;
  left: 20px; /* Position vom linken Rand */
  top: 150px; /* Position vom oberen Rand */
  z-index: 1000; /* Über allen anderen Elementen */
}

.aufgaben-button {
  background-color: #007bff; /* Hintergrundfarbe des Buttons */
  color: white; /* Textfarbe */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.aufgaben-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aufgaben-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.aufgaben-close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

/* Aufgaben-Kachel Ende */

/* Zielflagge Anfang */

.zielflagge {
  position: fixed;
  left: 250px; /* Position vom linken Rand */
  top: 1700px; /* Position vom oberen Rand */
  z-index: 1000; /* Über allen anderen Elementen */
  transform: scale(5);
}

/* Zielflagge Ende */

/* Abzeichen Kachel Anfang */


/* Positioniere die Badge-Kachel oben mittig */
.badge-tile {
  position: absolute;
  top: 80px; /* Abstand vom oberen Rand */
  left: 50%; /* Mittig auf der horizontalen Achse */
  transform: translateX(-50%); /* Korrigiere die horizontale Mitte */
  background: white; /* Hintergrundfarbe der Kachel */
  padding: 15px; /* Abstand innerhalb der Kachel */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Schatten für bessere Sichtbarkeit */
  z-index: 10; /* Stelle sicher, dass die Kachel über dem Inhalt liegt */
}

.badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.badge-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Abstand zwischen den Reihen */
}

/* Verschiebe den restlichen Inhalt nach unten */
.startseiteloggedin {
  padding-top: 300px; /* Stelle sicher, dass der Inhalt unter der Badge-Kachel beginnt */
}

.level{
  width: 70px; /* Größe der Icons nach Bedarf */
  height: 80px;
}

.star-bronze {
  width: 90px; /* Größe der Icons nach Bedarf */
  height: 100px;
}

.star-silber {
  width: 90px; /* Größe der Icons nach Bedarf */
  height: 100px;
}

.star-gold {
  width: 90px; /* Größe der Icons nach Bedarf */
  height: 100px;
}

.star-top1 {
  width: 95px; /* Größe der Icons nach Bedarf */
  height: 105px;
}

/* Abzeichen Kachel Ende */


/* Anfang CSS ProfilIcon */

.Info-Profil {
  width: 100%;
  text-align: center;
  z-index: 1;
}

.Info-Profil p {
  width: 100%;
  text-align: center;
  z-index: 1;
}

/* Ende CSS ProfilIcon */

.triangle {
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-right: 173.2px solid transparent; /* Farbe anpassen */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-14px, 0) rotate(45deg); /* Dreieck anpassen */
  transform-origin: top left;
  z-index: -1;
}
p {
    color: #000000; font-family: 'Helvetica Neue', sans-serif; font-size: 20px; line-height: 24px; margin: 0 0 24px; text-align: justify; text-justify: inter-word; 
}
.triangle-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 50% 100%); /* Bild auf das Dreieck zuschneiden */
}

.Lottie-Erklärungsdiv {
  position: absolute;
  bottom: 0;
  left: 50%; /* Horizontale Ausrichtung in der Mitte */
  transform: translateX(-50%); /* Verschiebt das Element um die Hälfte seiner Breite nach links */

  width: 80%;
  max-width: 300px;
}
.hr-modern{
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(246, 246, 246, 0.75), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.hr-modern2{
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(246, 246, 246, 0.75), rgba(0, 0, 0, 0));
}
.bottom-div a{
  margin: '0 10px';
  padding-left: 3%;
}

@media (max-width: 850px) {
  .radio-group{
    font-size: 11px;
    font-weight: bold;
  }
  .curved h1{
    font-size: 2rem;
    font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top:0;
    width: 50%;
  }

  .Erklärungs-Div{
    display: none;
  }
  .Lottie{
    visibility: hidden;
  }
  p{
    font-size: small;
  }
  .untergrund-Div {
     height: 100px;
    min-height: 242px;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 56%;
  }
  
  
  .circle-container {
visibility: hidden;
  }
  .Angebot-Div nav{
    display: flex;
    margin-left: -0%;
    gap: 5%; 
    max-height: 10px;
  }

  .Angebot-Div .button-87{
    position: relative;
    top: 25px;
  }

  
  .Überschrift_Animiert{
    font-size: 30px;

  }
  .Überschrift{
    font-size: 25px;

  }
  .Überschrift-Div {
    padding-top: 10%;
    text-align: left; /* Links-Ausrichtung */
    margin-left: -250px;
    align-items: center;
  }
  .first-Div{
    display: flex;
    background-color: rgb(235, 243,   253);
    border-radius: 10px 10px 0 0;
    height: 100px;
    min-height: 240px;

    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 60%;
  }

  .menue{
    width: 40px;
    height: 40px;
    position: relative;
    left: 75%;
    margin-top: -5px;
    cursor: pointer;
  }
  .einloggen{
    position: relative;
    left:73%;
    cursor: pointer;
     margin-top: 3px;
     color: rgb(76, 76, 249);
     font-weight: bold;
     font-size: smaller;
   }
   nav {
    margin-left: -30%;
    display: flex; /* Um die Links nebeneinander anzuordnen */
    gap: 1%; /* Abstand zwischen den Spalten */
  }
  .logo-Impressum {
    text-align: center;
    position: relative;
   margin-top: -80px;
   margin-left: -5%;
    max-width: 80px; /* Maximalbreite des Logos */
  }
  .column {
    padding-left: 25%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Abstand zwischen den Links in einer Spalte */
  }
  .column1 {
    margin-top: -5px;
    margin: 0 auto;
    gap: 3px; /* Abstand zwischen den Links in einer Spalte */
  }

  .column1 a{
    font-size: 9px;
    font-weight: bold;
    
  }

  .Login_Form {
    align-items: center;
    width:300px;
    bottom: 10%;
  }
  .bottom-div a{
    margin: '0 10px';
    padding-left: 3%;
    margin-bottom: -3%;
  }
  .svg-curve{
    visibility: hidden;
  }
  .login-headline{
    font-size: small;
  }
}

@media (min-width: 1501px) {

  .Überschrift{
    font-size: 70px;

  }
  .Überschrift-Div {
    margin-top: -50px;
    margin-left: 10%;
    text-align: left; /* Links-Ausrichtung */

  }
  .Überschrift_Animiert{
    font-size: 60px;

  }

}

/* Impressum und Datenschutz Anfang */

.centerDiv {
  background-color: #f2f2f2; /* Anfangsfarbe */
  padding: 20px; /* Abstand zum Inhalt */
  font-family: Arial, sans-serif; /* Schriftart */
  text-align: center;
  color: #333; /* Schriftfarbe */
  animation: changeBackground 20s infinite alternate;
}

.container_Impressum-Datenschutz {
  background-color: #f2f2f2; /* Anfangsfarbe */
  padding: 20px; /* Abstand zum Inhalt */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Schatten */
  font-family: Arial, sans-serif; /* Schriftart */
  color: #333; /* Schriftfarbe */
  animation: changeBackground 20s infinite alternate;
}

.container_Impressum-Datenschutz p {
  text-align: center;
}

.Impressum-Datenschutz {
  display: inline-block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #6200ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

/* Impressum und Datenschutz Ende */



/* Über uns Anfang */

/* Allgemeines Styling */
.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 80px; /* Standardgröße für größere Bildschirme */
  height: auto; /* Erhält das Seitenverhältnis */
}

.container_aboutus {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1200px;
  max-height: 600px;
  background-color: whitesmoke;
  align-items: center;
  text-align: center;
  display: grid;

  /*Class-Effect*/
  background: rgba(58, 14, 203, 0.52);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.title {
  display: grid;
  justify-content: center;
  color: black;
  position: relative;
  top: 10%;
  font-weight: bold;
  font-size: xx-large;
  text-shadow: 2px 2px rgba(255, 255, 255, 0.699);
}

/* Anpassung für Smartphones */
@media (max-width: 768px) {
  .logo {
    width: 50px; /* Kleinere Größe für Smartphones */
  }

  .container_aboutus {
    max-width: 90%; /* Passt die Breite an den Bildschirm an */
    max-height: 90%; /* Passt die Höhe an den Bildschirm an */
  }

  .title {
    font-size: large; /* Kleinere Schriftgröße auf Smartphones */
  }
}

/* Über uns Ende */

/* Startseite Button Anfang */


.info-startseite {
  background-color: black; /* Hintergrundfarbe des Buttons */
  color: blue; /* Textfarbe */
  border: none;
  padding: 1rem 2rem; /* Relativere Padding-Größen */
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem; /* Verwende rem für eine responsive Schriftgröße */
}

/* Für größere Bildschirme wie Laptops */
@media (min-width: 768px) {
  .info-startseite {
    padding: 1.2rem 2.4rem; /* Größeres Padding für größere Bildschirme */
    font-size: 1.2rem; /* Größere Schriftgröße */
  }
}

/* Für kleinere Bildschirme wie Mobilgeräte */
@media (max-width: 767px) {
  .info-startseite {
    padding: 0.8rem 1.6rem; /* Kleinere Padding-Größen für Mobilgeräte */
    font-size: 0.9rem; /* Kleinere Schriftgröße */
  }
}


/* Startseite Button Ende */


/* Anfang Schild Logo Startseite oben */

.logoStartseite {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px; /* Passe die Größe des Logos nach Bedarf an */
  height: auto; /* Erhält das Seitenverhältnis */
}

/* Ende Schild Logo Startseite oben */ 

/* Anfang "Passwort vergessen und Kostenlosen Account erstellen" auf dem Smartphone nach unten schieben */

@media (max-width: 768px) {
  .move-down {
    margin-top: 20px;
    display: block;
  }
}

/* Ende "Passwort vergessen und Kostenlosen Account erstellen" auf dem Smartphone nach unten schieben */

/* Anfang Styling Lottie */

.lottie-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  top: 100px;
  z-index: -10;
  height: 100%;
}

.stars {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.star {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}

.Lottie {
  width: 20%;
}

/* Anpassungen für kleinere Bildschirme */
@media (max-width: 768px) { /* Tablets und Smartphones */
  .lottie-container {
    width: 90%; /* Verkleinert die Breite auf 90% des Bildschirms */
    top: 50px; /* Passt den Abstand zum oberen Rand an */
    flex-direction: column;
    align-items: center;
  }

  .Lottie {
    width: 40%; /* Vergrößert die Breite der Lottie-Animation für kleinere Bildschirme */
  }

  .lottie-animation {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 480px) { /* Kleine Smartphones */
  .lottie-container {
    width: 100%; /* Setzt die Breite auf 100% */
    top: 20px; /* Verringert den Abstand zum oberen Rand */
  }

  .Lottie {
    width: 60%; /* Vergrößert die Breite für noch kleinere Bildschirme */
  }
}


/* Ende Styling Lottie */

/* Anfang Design Quiz Choice Smartphone */

/* Allgemeine Anpassungen für mobile Geräte */
@media only screen and (max-width: 600px) {
  .quiz-container {
    padding: 10px;
    font-size: 16px;
  }

  .Erklärungscard-Div {
    margin-bottom: 20px;
  }

  .options {
    flex-direction: column;
    align-items: flex-start;
  }

  .options label {
    font-size: 14px;
    white-space: normal; /* Verhindert das Abschnibbeln des Texts */
  }

  .button-87 {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }

  .logo {
    width: 40px;
    height: auto;
  }
  
  /* Fortschrittsbalken anpassen */
  .progress-bar {
    height: 20px;
    font-size: 12px;
  }
}


/* Ende Design Quiz Choice Smartphone */

